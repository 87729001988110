import { withDependencies } from '@wix/thunderbolt-ioc'
import { ILinkClickHandler } from '@wix/thunderbolt-symbols'
import { ISamePageScroll } from './types'
import { SamePageScrollSymbol } from './symbols'

export const samePageScrollClickHandlerFactory = (samePageScroll: ISamePageScroll): ILinkClickHandler => ({
	handleClick: (anchor) => {
		const anchorCompId = anchor.getAttribute('data-anchor-comp-id') || ''
		const anchorDataId = anchor.getAttribute('data-anchor') || ''

		return samePageScroll.scrollToAnchor({ anchorDataId, anchorCompId })
	},
})

export const SamePageScrollClickHandler = withDependencies([SamePageScrollSymbol], samePageScrollClickHandlerFactory)
